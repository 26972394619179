import React, { useState } from "react"
import Icon from "../icon"

const PhotoGallery = (props) => {

  const { images, style } = props

  const [current, setCurrent] = useState(0);
  const length = images.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(images) || images.length <= 0) {
    return null;
  }


  const getTitle = (image) => {
    return image?.alt || image?.title
  }

  return(
    <section className="gallery slider" style={style}>
      <Icon className='left-arrow' onClick={prevSlide}  icon="icons-32-x-32-export-arrow" size={24} />
      <Icon className='right-arrow' onClick={nextSlide}  icon="icons-32-x-32-export-arrow" size={24} />
      {images && images.map((image, index) => {
        return(
          <div
          className={index === current ? 'slide active' : 'slide'}
          key={index}
          >
          {index === current && (
            <img src={image.image_style_uri._653_432} alt={getTitle(image)} className='image' />
          )}
        </div>
        )
      })}
    </section>
  )
}

export default PhotoGallery
