import React, { useContext, useRef, useState, useEffect  } from "react"
import { LanguageContext } from "../../context"
import Banner from "../banner"
import ProjectItem from "../projects/project-item"
import Button from "../button-visit-us"
import Button2 from "../button-generic"
import { navigate, Link } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import Utils from "../../utils"
import PropTypes from "prop-types"
import PhotoGallery from "./photo-gallery"


const Sectionboxedwithimage = (props) => {

  const {
    color,
    title,
    body,
    image,
    imagePos,
    path,
    button,
    isArray,
    content,
    cta,
    className
  } = props

  const breakpoints = useBreakpoint()
  const { t } = useContext(LanguageContext)

  const [parentHeight, setParentHeight] = useState('auto')
  const childRef = useRef(null)

  useEffect(() => {
    const childHeight = childRef?.current?.offsetHeight;
    const finalHeight = childHeight + 34;
    setParentHeight(`${finalHeight}px`);
  }, []);





  return(
    <React.Fragment>
    {breakpoints.md

    ?
        <div className={className}>
          <div className="background-beige m-b-42 small-m-b-0 col-md-6 small-p-0 small-p-b-14 medium-m-b-82" style={{maxHeight: breakpoints.xs_sm ? 'initial' :  parentHeight,  minHeight: breakpoints.xs_sm ? 'initial' :  parentHeight, paddingBottom: '24px'}}>
          <img
            src={image}
            alt={title}
            className="m-r--24"
            ref={childRef}
            onLoad={() => {
              const childHeight = childRef.current.offsetHeight;
              setParentHeight(`${childHeight}px`);
            }}
          />
          {cta?.title && cta.title === "Visita'ns"

            ?
            <Button
              title={cta.title}
              backgroundimage={image}
              className="r-more bordeaux"
              key={cta?.title}
            />
            :
            <Link
             className="r-more bordeaux"
             to={Utils.removeMainLanguage(cta?.url)}
             >
             {cta?.title}
             </Link>
           }
          </div>
          <div className="about-us-desc col-md-6">
            <h3 className="H3-Bold-L---orange">{title}</h3>
            <div className="H5-Light-L---black" dangerouslySetInnerHTML={{__html: body}} />
          </div>
          </div>
    :
      <div className={className}>
        <div className="background-beige m-b-42 small-m-b-0 box-padding-left-bottom" style={{maxHeight: breakpoints.xs_sm ? 'initial' :  parentHeight, minHeight: breakpoints.xs_sm ? 'initial' :  parentHeight, paddingBottom: breakpoints.md ? 34 : 54 }}>
        {
          Array.isArray(image)
          ?
          <PhotoGallery
            images={image}
            style={{height: breakpoints.xs_sm ? 'initial' :  parentHeight }}
          />
          :
          <img
            src={image}
            alt={title}
            className="m-r--24"
            ref={childRef}
            onLoad={() => {
              const childHeight = childRef.current.offsetHeight;
              setParentHeight(`${childHeight + 34}px`);
            }}
          />
        }


        {cta?.title && cta.title === "Visita'ns"

          ?
          <Button
            title={cta.title}
            backgroundimage={image}
            className="r-more bordeaux"
            key={cta?.title}
          />
          :
          <Link
           className="r-more bordeaux"
           to={Utils.removeMainLanguage(cta?.url)}
           >
           {cta?.title}
           </Link>
         }


        </div>
        <div className="about-us-desc m-b-88">
          <h3 className="H3-Bold-L---orange">{title}</h3>
          <div className="H5-Light-L---black" dangerouslySetInnerHTML={{__html: body}} />
        </div>
        </div>
      }
    </React.Fragment>
  )
}

Sectionboxedwithimage.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  image: PropTypes.string,
  imagePos: PropTypes.string,
  path: PropTypes.string,
  button: PropTypes.string,
  isArray: PropTypes.bool,
  content: PropTypes.arrayOf(PropTypes.object),
  ctas: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string
}

Sectionboxedwithimage.defaultProps = {
  color: '',
  title: '',
  body: '',
  image: '',
  imagePos: '',
  path: '',
  button: '',
  isArray: false,
  content: [],
  ctas: [],
  className: ``,
}

export default Sectionboxedwithimage
